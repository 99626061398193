import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { useParams } from 'react-router'
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Button,
} from '@chakra-ui/react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  DateRangePicker,
  DesktopDateTimePicker,
} from '@mui/x-date-pickers/DesktopDateTimePicker'
// import { DatePicker } from 'chakra-ui-date-input'

//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import RenderDataForm from './components/RenderDataForm'
import RenderDataSubForm from './components/RenderDataSubForm'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import BackButton from 'components/Button/BackButton'
import FunctionPrintReport from '../../components/function/FunctionPrintReport'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import SteamReport2 from 'components/ReportPDF/ThaitallowReport/SteamReport2'
import th from 'dayjs/locale/th'

export default function ReportDetail() {
  const params = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const form = useSelector((state) => state.form)
  const formInput = useSelector((state) => state.formInput)
  const subForm = useSelector((state) => state.subForm)
  const system = useSelector((state) => state.system)
  const [value, setValue] = React.useState([null, null])
  const [date, setDate] = useState(dayjs().hour(8).minute(0).toDate())
  const [dateEnd, setDateEnd] = useState(
    dayjs().add(1, 'day').hour(8).minute(0).toDate()
  )
  const [checkedMonth, setCheckedMonth] = useState(false)
  const [checkedAll, setCheckedAll] = useState(false)

  useEffect(() => {
    dispatch(actions.formGet(params.id))

    return () => {}
  }, [])

  useEffect(() => {
    if (form?.subForm_status) {
      dispatch(actions.subFormAll({ form: params.id }))
    }
    return () => {}
  }, [form])

  useEffect(() => {
    if (form?.name) {
      dispatch(
        actions.formInputAll(params?.id, {
          ...form,
          date,
          dateEnd,
          checkedMonth,
        })
      )
    }
    return () => {}
  }, [form, date, dateEnd, checkedMonth])

  const queryMonth = (e) => {
    setCheckedMonth(e.target.checked)
  }
  //console.log('CheckMonth', checkedMonth)
  const queryAll = (e) => {
    setCheckedAll(e.target.checked)
    if (e.target.checked === true) {
      setDate('')
    }
    if (e.target.checked === false) {
      setDate(new Date())
    }
  }

  const renderFilter = () => (
    <div className="flex justify-between">
      <div className="w-full gap-2 flex flex-wrap my-2">
        <label className="block uppercase text-gray-700 text-xs font-bold my-3 mx-2">
          {t('dataAndStat.timePeriod')}
        </label>
        <div className="flex">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="เลือกเวลา"
              inputFormat={'DD MMMM BBBB HH:mm'}
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
            />{' '}
            <label className="block uppercase text-gray-700 text-xs font-bold my-3 mx-2">
              ถึง
            </label>
            <DesktopDateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="เลือกเวลา"
              inputFormat={'DD MMMM BBBB HH:mm'}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue)
              }}
            />
          </LocalizationProvider>
        </div>
        <FormControlLabel
          label="รายเดือน"
          control={<Checkbox checked={checkedMonth} onChange={queryMonth} />}
        />
        <FormControlLabel
          label="รายการทั้งหมด"
          control={<Checkbox checked={checkedAll} onChange={queryAll} />}
        />
      </div>

      <div>
        <Button
          colorScheme="green"
          size="sm"
          onClick={() => {
            console.log('formInput onClick', formInput)

            FunctionPrintReport(form, formInput, subForm, system, date)
          }}
        >
          PRINT
        </Button>
      </div>
    </div>
  )
  const renderDataNormalForm = () => (
    <RenderDataForm form={form} formInput={formInput} />
  )
  const renderDataWithSubForm = () => (
    <RenderDataSubForm form={form} formInput={formInput} subForm={subForm} />
  )

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                รายงานแบบฟอร์ม
              </h6>
              <h2 className="text-gray-800 text-2xl font-bold font-sans ">
                {form?.name}
              </h2>
              <BackButton />
            </div>
          </div>
          {renderFilter()}
          {form?.subForm_status
            ? renderDataWithSubForm()
            : renderDataNormalForm()}
        </div>
      </div>
    </div>
  )
}
