import { combineReducers } from 'redux'

// Common
import UserReducers from './common/UserReducers'
import MeReducers from './common/MeReducers'
import SystemInfoReducers from './common/SystemReducer'

// Feature
import StationReducers from './feature/StationReducers'
import SensorReducers from './feature/SensorReducers'
import LogReducers from './feature/LogReducers'
import StationTemplateReducers from './feature/StationTemplateReducers'
import ElectricConsumptionReducers from './feature/ElectricConsumptionReducers'
import CountingSummaryReducers from './feature/CountingSummaryReducers'
import DiagramReducers from './feature/DiagramReducers'
import FormReducers from './feature/FormReducers'
import FormInputReducers from './feature/FormInputReducers'
import SubFormReducers from './feature/SubFormReducers'
import PredictionReducers from './feature/PredictionReducers'

const rootRuducer = combineReducers({
  me: MeReducers,
  user: UserReducers,
  station: StationReducers,
  sensor: SensorReducers,
  log: LogReducers,
  stationTemplate: StationTemplateReducers,
  system: SystemInfoReducers,
  electricConsumption: ElectricConsumptionReducers,
  countingSummary: CountingSummaryReducers,
  diagram: DiagramReducers,
  form: FormReducers,
  formInput: FormInputReducers,
  subForm: SubFormReducers,
  logPrediction: PredictionReducers,
})
export default rootRuducer
