import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import * as actions from '../../redux/actions'
import _ from 'lodash'
import { Box, Button, Link as LinkUI } from '@chakra-ui/react'
import BackButton from 'components/Button/BackButton'
export default function ReportStationList(params) {
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const station = useSelector((state) => state.station)
  const [allowedDashboard, setAllowedDashboard] = useState()
  useEffect(() => {
    dispatch(actions.stationAll({}))
    return () => {}
  }, [])

  useEffect(() => {
    if (me && me._id && station) {
      if (_.size(station.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = station.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            station.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [station.arr, me])
  const renderStation = () =>
    _.map(allowedDashboard, (row) => (
      <div className="my-4  px-4">
        <Link to={`/humaninput/report/form/${row?._id}`}>
          <Box padding="4" borderRadius="lg" borderWidth="1px" className="mb-3">
            <LinkUI>
              <h3 className="font-bold text-xl font-sans">
                <i className="fas fa-search text-sm"> </i> {row?.name}{' '}
              </h3>
            </LinkUI>

            <h5 className="text-base text-gray-700 font-sans">
              {row.description} | {row.location}{' '}
            </h5>
          </Box>
        </Link>
      </div>
    ))

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              เลือกสถานีที่่ต้องการ
            </h6>
            <BackButton />
            {renderStation()}
          </div>
        </div>
      </div>
    </div>
  )
}
