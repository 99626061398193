/* eslint-disable consistent-return */
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Button,
} from '@chakra-ui/react'
import _ from 'lodash'
import dayjs from 'dayjs'

export default function RenderDataHistorySubForm({
  form,
  formInput,
  subForm,
  formId,
  modalOpen = () => {},
  handleDelete = () => {},
}) {
  const mergeForm = _.merge(subForm)

  const checkHeadTable = (data) => {
    if (data?.formType === 'CALCULATE') {
      return (
        <Td w="10%" borderColor={'black'} borderBottom={'1px'} borderX="1px">
          <div className="text-center"></div>
        </Td>
      )
    } else if (data?.attribute?.length === 0 || data?.formType === 'SELECTOR') {
      return (
        <Td w="10%" borderColor={'black'} borderBottom={'1px'} borderX="1px">
          <div className="text-center"></div>
        </Td>
      )
    } else {
      return _.map(data?.attribute, (eachCol) => (
        <Th w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">{eachCol?.subTopic}</div>
        </Th>
      ))
    }
  }

  const checkType = (data) => {
    if (typeof data === 'number') {
      return data.toFixed(2)
    } else if (typeof data === 'boolean') {
      return <div>{data === true ? <i className="fas fa-check"></i> : ''}</div>
    } else if (data === dayjs(data).format('YYYY-MM-DD')) {
      return dayjs(data).format('D/MM/BBBB')
    }
    return data
  }

  const renderTableBody = (colName, rows, index) => {
    if (colName?.formType === 'CALCULATE') {
      return (
        <Td w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">
            {checkType(rows[colName?.name]) || '-'}
          </div>
        </Td>
      )
    } else if (
      colName?.attribute?.length === 0 ||
      colName?.formType === 'SELECTOR'
    ) {
      return (
        <Td w="10%" borderBlock={'black'} border="1px">
          <div className="text-center">
            {checkType(rows[colName?.name]) || '-'}
          </div>
        </Td>
      )
    } else {
      return _.map(colName?.attribute, (eachCol) => (
        <Td w="10%" borderBlock={'black'} border="1px">
          <div className="text-center ">
            {checkType(rows?.[colName?.name]?.[eachCol?.subTopic]) || '-'}
          </div>
        </Td>
      ))
    }
  }

  const renderCard = () => (
    <div>
      <TableContainer>
        {_.map(mergeForm?.arr, (row, index) => (
          <div className="pt-4">
            <Table variant="striped" colorScheme="gray" size="sm" border="2px">
              <Thead bg={'gray.200'} border="1px">
                <Tr borderColor="black" key={index} borderTop="1px">
                  {_.map(row?.components, (rowName) => (
                    <Td
                      borderBlock={'black'}
                      borderX="1px"
                      borderBottom={
                        rowName?.formType === 'SELECTOR' ? '1px' : '0px'
                      }
                      sx={{ width: '10' }}
                      w="100%"
                      colSpan={_.size(
                        rowName?.formType === 'SELECTOR'
                          ? 1
                          : rowName?.formType === 'CALCULATE'
                          ? 1
                          : rowName?.attribute
                      )}
                    >
                      <div className="text-center font-bold">
                        {rowName?.topic}
                      </div>
                      <div className="text-center pt-2 border-black">
                        {rowName?.description}
                      </div>
                    </Td>
                  ))}
                </Tr>
                <Tr>
                  {_.map(row?.components, (row, index) =>
                    checkHeadTable(row, index)
                  )}
                </Tr>
              </Thead>
              {_.map(
                _.orderBy(
                  formInput?.arr,
                  _.map(
                    row?.components,
                    (eachComponent) => eachComponent?.topic
                  ),
                  'asc'
                ),
                (rows, index) =>
                  row?.name === rows?.subForm_name ? (
                    <Tr borderColor="black" key={index} border="1px">
                      {_.map(row?.components, (colName) =>
                        renderTableBody(colName, rows)
                      )}
                      <Td style={{ border: 'none' }}>
                        <Button
                          colorScheme="yellow"
                          size="xs"
                          onClick={() => modalOpen(row)}
                        >
                          <Link
                            to={`/humaninput/history/formList/historyDetail/edit/${form?._id}/${row?._id}/${rows?._id}`}
                          >
                            แก้ไข
                          </Link>
                        </Button>
                        <Button
                          colorScheme="red"
                          size="xs"
                          onClick={() => {
                            handleDelete(rows?._id)
                          }}
                        >
                          ลบ
                        </Button>
                      </Td>
                    </Tr>
                  ) : (
                    <Tr></Tr>
                  )
              )}
            </Table>
          </div>
        ))}
      </TableContainer>
    </div>
  )

  return renderCard()
}
