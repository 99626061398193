import React from 'react'
import { Box, Button } from '@chakra-ui/react'

export default function StaticNumberGauge({
  staticData,
  unit = '',
  graphLabel = '',
  favoriteButton,
  selectedSensor,
}) {
  const fixData = (data) => {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(data)
  }
  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-2 w-full  md:w-full  h-25"
    >
      <div className="flex flex-row">
        {favoriteButton}
        <h5 className="font-bold text-2xl px-2"> {graphLabel} </h5>
      </div>
      <div>
        <h1
          className={`font-bold text-4xl ${
            staticData === null ? 'text-yellow-500' : 'text-blue-500'
          } mb-0`}
        >
          {staticData === null ? (
            0
          ) : selectedSensor?.error ? (
            <span className="text-red-500 text-2xl">
              {selectedSensor?.errorMessage}
            </span>
          ) : (
            fixData(staticData?.toFixed(2))
          )}
        </h1>{' '}
        <h3 className="font-semibold text-lg"> {unit}</h3>
      </div>
    </Box>
  )
}
