import axios from "axios";
import {
  STATION_TEMPLATE_ALL,
  STATION_TEMPLATE_GET,
  STATION_TEMPLATE_PUT,
  STATION_TEMPLATE_DEL,
} from "../types";

export const stationTemplateAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/template").then((res) => {
      console.log("Request Server to Get All Stations");
      dispatch({ type: STATION_TEMPLATE_ALL, payload: res.data });
    });
  };
};

export const stationTemplateGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/template/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Stations");
        if (res.data) {
          dispatch({ type: STATION_TEMPLATE_GET, payload: res.data });
        } else {
          dispatch({ type: STATION_TEMPLATE_GET, payload: null });
        }
      });
  };
};

export const stationTemplatePut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/template/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Stations");
        dispatch({ type: STATION_TEMPLATE_PUT, payload: null });
        dispatch(stationTemplateGet(id));
      });
  };
};

export const stationTemplatePost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/template/", payload)
      .then((res) => {
        console.log("Request to Create New Station");
        dispatch(stationTemplateAll());
      });
  };
};
export const stationTemplateDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/template/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Stations");
        dispatch({ type: STATION_TEMPLATE_DEL, payload: null });
      });
  };
};
