import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import { ErrorBoundary } from 'react-error-boundary'

// components
import Sidebar from 'components/common/Sidebar/mainSidebar'
import FooterAdmin from 'components/common/Footers/FooterAdmin'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import Fallback from '../components/error/Fallback'

// views

// Management
import ManagementUsers from '../views/system/management-users/ManagementUsers'
import ManagementStations from '../views/system/management-statations/ManagementStations'
import ManagementStationTemplate from '../views/system/management-template/ManagementTemplate'
import ManagementDashboard from '../views/system/management-dashboard/ManagementDashboard'
import ManagementCharts from '../views/system/management-statations/Pages/ManagementCharts'
import ManagementSystemInfo from '../views/system/management-system/ManagementSystem'
import ManagementVariables from '../views/system/management-statations/Pages/ManagementVariables'
import ManagementConnections from '../views/system/management-connections/ManagementConnections'
import ManagementNotify from '../views/system/management-notify/ManagementNotify'
import ManagementEquations from '../views/system/management-statations/Pages/ManagementEquations'
import ManagementForm from 'views/system/management-statations/Pages/ManagementForm'
import ManagementPredictions from 'views/system/management-statations/Pages/ManagementPredictions'

// Create
import CreateUsers from 'views/system/CreateUser'
import CreateStation from 'views/system/management-statations/Pages/CreateStation'
import CreateSensor from '../views/system/management-statations/Pages/AddSensor'
import CreateSensorInTemplate from 'views/system/management-template/AddSensor'
import CreateTemplate from 'views/system/management-template/CreateTemplate'
import CreateEquation from '../views/system/management-statations/Pages/AddEquation'
import CreatePrediction from '../views/system/management-statations/Pages/AddPrediction'
import AddFormComponent from '../views/system/management-statations/Pages/AddFormComponent'
import CreateForm from '../views/system/management-statations/Pages/CreateForm'

import EditStation from '../views/system/management-statations/Pages/EditStation'
import ArrangementStation from '../views/system/management-statations/Pages/ArrangementStation'
import EditSensor from '../views/system/management-statations/Pages/EditSensor'
import EditTemplateSensor from '../views/system/management-template/EditSensor'
import EditDiagramOrder from '../views/system/management-dashboard/Pages/EditDiagramOrder'
import EditGaugeOrder from '../views/system/management-dashboard/Pages/EditGaugeOrder'
import EditChartOrder from '../views/system/management-dashboard/Pages/EditChartOrder'
import GaugeList from '../views/system/management-dashboard/Pages/GaugeList'
import DiagramList from '../views/system/management-dashboard/Pages/DiagramList'
import EditForm from '../views/system/management-statations/Pages/EditForm'
import AddSubFormComponent from 'views/system/management-statations/Pages/AddSubFormComponent'
import CreateSubForm from 'views/system/management-statations/Pages/CreateSubForm'
import EditSubForm from 'views/system/management-statations/Pages/EditSubForm'
import ReportSetting from 'views/system/management-statations/Pages/ReportSetting'

import * as UserRole from '../config/UserRoles'
import EditControlOrder from '../views/system/management-dashboard/Pages/EditControlOrder'


export default function SystemLayout() {
  const history = useHistory()
  const authorize_role = [
    UserRole.ROLES.USER,
    UserRole.ROLES.GENERAL,
    UserRole.ROLES.ADMIN,
    UserRole.ROLES.SUPER_USER,
  ] // Role ที่ได้รับอนุญาต

  const storage_remember = JSON.parse(window.localStorage.eiot_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log('SystemLayout : ACTIVE')
    dispatch(actions.meGet(storage_remember.uid)).then(() => {})
    dispatch(actions.systemInfoGet())
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me) {
      if (me?.role === authorize_role[3]) {
        setIsLoading(true)
        console.log('ได้รับการอนุญาตเข้าถึง')
      } else {
        console.log('ไม่ได้รับอนุญาต')
        alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้')
        history.push('/')
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={() => {
              history.goBack()
            }}
          >
            <Switch>
              <Route path="/system/users" exact component={ManagementUsers} />
              <Route path="/system/user/create" exact component={CreateUsers} />

              <Route
                path="/system/stations"
                exact
                component={ManagementStations}
              />
              <Route
                path="/system/stations/create"
                exact
                component={CreateStation}
              />
              <Route
                path="/system/stations/ArrangementStations"
                exact
                component={ArrangementStation}
              />
              <Route
                path="/system/stations/:id"
                exact
                component={EditStation}
              />

              <Route
                path="/system/stations/sensors/add/:stationId"
                exact
                component={CreateSensor}
              />

              <Route
                path="/system/stations/sensors/:id"
                exact
                component={EditSensor}
              />
              <Route
                path="/system/template/"
                exact
                component={ManagementStationTemplate}
              />
              <Route
                path="/system/template/create"
                exact
                component={CreateTemplate}
              />
              <Route
                path="/system/template/sensors/add/:templateId"
                exact
                component={CreateSensorInTemplate}
              />
              <Route
                path="/system/template/sensors/:id"
                exact
                component={EditTemplateSensor}
              />

              <Route
                path="/system/dashboard/"
                exact
                component={ManagementDashboard}
              />
              <Route
                path="/system/stations/gauge/:id"
                exact
                component={GaugeList}
              />
              <Route
                path="/system/stations/diagram/arrange/:id"
                exact
                component={EditDiagramOrder}
              />
              <Route
                path="/system/stations/diagram/:id"
                exact
                component={DiagramList}
              />

              <Route
                path="/system/stations/arrange/:id"
                exact
                component={EditGaugeOrder}
              />

              <Route
                path="/system/stations/variables/:id"
                exact
                component={ManagementVariables}
              />

              <Route
                path="/system/stations/charts/arrange/:id"
                exact
                component={EditChartOrder}
              />
              <Route
                path="/system/stations/control/arrange/:id"
                exact
                component={EditControlOrder}
              />

              <Route
                path="/system/stations/charts/:id"
                exact
                component={ManagementCharts}
              />
              <Route
                path="/system/connections"
                exact
                component={ManagementConnections}
              />
              <Route path="/system/notify" exact component={ManagementNotify} />
              <Route
                path="/system/info/"
                exact
                component={ManagementSystemInfo}
              />
              <Route
                path="/system/stations/equations/:id"
                exact
                component={ManagementEquations}
              />
               <Route
                path="/system/stations/predictions/:id"
                exact
                component={ManagementPredictions}
              />
              <Route
                path="/system/stations/form/management/:stationId"
                exact
                component={ManagementForm}
              />
              <Route
                path="/system/stations/form/create/:stationId"
                exact
                component={CreateForm}
              />
              <Route
                path="/system/stations/subform/create/:formId"
                exact
                component={CreateSubForm}
              />
              <Route
                path="/system/stations/form/:stationId/:formId"
                exact
                component={EditForm}
              />
              <Route
                path="/system/stations/report/:stationId/:formId"
                exact
                component={ReportSetting}
              />
              <Route
                path="/system/stations/form/add/:stationId/:formId"
                exact
                component={AddFormComponent}
              />
              <Route
                path="/system/stations/subform/edit/:formId"
                exact
                component={EditSubForm}
              />
              <Route
                path="/system/stations/form/subForm/add/:subForm"
                exact
                component={AddSubFormComponent}
              />
              <Route
                path="/system/stations/equations/add/:stationId"
                exact
                component={CreateEquation}
              />
              <Route
                path="/system/stations/predictions/add/:stationId"
                exact
                component={CreatePrediction}
              />

              <Redirect from="/system" to="/system/stations" />
            </Switch>
          </ErrorBoundary>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <div>
      <SpinnerLoading />
    </div>
  )
}
