import React from 'react'
import { useHistory } from 'react-router'

export default function BackButton() {
  const history = useHistory()
  return (
    <div className="flex">
      <div onClick={() => history.goBack()} className="font-bold text-gray-600">
        <i class="fas fa-angle-double-left"></i> กลับ
      </div>
    </div>
  )
}
