import {
  STATION_TEMPLATE_ALL,
  STATION_TEMPLATE_GET,
  STATION_TEMPLATE_PUT,
  STATION_TEMPLATE_DEL,
  STATION_TEMPLATE_POST,
} from "../../actions/types";

const initialState = {
  station: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case STATION_TEMPLATE_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case STATION_TEMPLATE_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case STATION_TEMPLATE_POST:
      return action.payload;
    case STATION_TEMPLATE_PUT:
      return action.payload;
    case STATION_TEMPLATE_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
