/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { Badge, Box, Button } from '@chakra-ui/react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import 'katex/dist/katex.min.css'

import VariableMainRendering from '../Function/VariableMainRendering'
import { DataType } from '../../../config/DataType'
import Gauge from '../../Gagues/common'
import VariableMakingComponent from './VariableMakingComponents'
import * as actions from '../../../redux/actions'

export default function EachVariableBox({ index, stationData, variableData }) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const dispatch = useDispatch()

  const prepareDeleteVariable = () => {
    let confirm = window.confirm('ยืนยันการลบตัวแปร')
    if (confirm) {
      const selectedSensorIndex = index
      let editedStation = stationData
      if (editedStation.variables) {
        editedStation.variables.splice(selectedSensorIndex, 1)
      }
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
      setIsEditorMode(false)
    }
  }

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      {isEditorMode === false && (
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {variableData.variableName}{' '}
              <Badge colorScheme="teal" size="sm">
                {variableData.wantedDataType}{' '}
              </Badge>{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            <Button
              size="sm"
              colorScheme="yellow"
              onClick={() => setIsEditorMode(true)}
              type="button"
            >
              {t('utility.edit')}
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              type="button"
              onClick={() => prepareDeleteVariable()}
            >
              {t('utility.delete')}
            </Button>
          </div>
        </div>
      )}

      <div className="w-full">
        {isEditorMode === true ? (
          <div>
            <VariableMakingComponent
              index={index}
              stationData={stationData}
              variableData={variableData}
              setIsEditorMode={setIsEditorMode}
            />
          </div>
        ) : (
          <div>
            <div className="flex flex-wrap ">
              <div className="lg:w-1/3 font-sans">
                {variableData.wantedDataType === DataType.boolean && (
                  <Gauge.StaticGauge.StaticBoolean
                    staticData={VariableMainRendering(
                      stationData,
                      variableData.id
                    )}
                    graphLabel={variableData.variableName}
                  />
                )}
                {/* {console.log('variableData.numericStatus', variableData)} */}

                {variableData.wantedDataType === DataType.number && (
                  <Gauge.StaticGauge.StaticNumber
                    staticData={variableData.numericStatus}
                    graphLabel={variableData.variableName}
                  />
                )}
              </div>
              <div className="lg:w-2/3">
                <ul className="list-inside list-disc ml-6">
                  <li>
                    <span className="text-sm font-semibold text-gray-700">
                      {t('sensor.subSensorType')} :{' '}
                    </span>
                    <span className="text-sm font-semibold text-blue-700">
                      {variableData.wantedDataType}{' '}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </Box>
  )
}
