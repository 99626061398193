import {
    SENSOR_ALL,
    SENSOR_GET,
    SENSOR_PUT,
    SENSOR_DEL,
    SENSOR_POST,
  } from "../../actions/types";
  
  const initialState = {
    sensor: null,
    isLoading: false,
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case SENSOR_ALL:
        return {
          arr: [...action.payload],
          isLoading: true,
        };
      case SENSOR_GET:
        return {
          ...action.payload,
          isLoading: true,
        };
      case SENSOR_POST:
        return action.payload;
      case SENSOR_PUT:
        return action.payload;
      case SENSOR_DEL:
        return { isLoading: true };
      default:
        return state;
    }
  }
  