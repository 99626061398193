import { GaugeType } from './GaugeType'
import { ChartType } from './ChartType'
import { ChartCurve } from './ChartCurve'
import { SensorGaugeType } from './SensorType' // aka SensorSubType
import { NumberSensorType } from './NumberSensorType'
import { DiagramType } from './DiagramType'

export {
  GaugeType,
  ChartType,
  ChartCurve,
  SensorGaugeType,
  NumberSensorType,
  DiagramType,
}
export default {
  GaugeType,
  ChartType,
  ChartCurve,
  SensorGaugeType,
  NumberSensorType,
  DiagramType,
}
