/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
// components
import { Button, Link as LinkUI, Box } from '@chakra-ui/react'
import dayjs, { Dayjs } from 'dayjs'
import SpinnerLoading from '../../../components/Loading/SpinnerLoading'
import _ from 'lodash'
import GeneralFormSelector from '../../../components/Form/GeneralFormSelector'
import * as actions from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

export default function RenderSubForm() {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useSelector((state) => state.me)
  const form = useSelector((state) => state.form)
  const subForm = useSelector((state) => state.subForm)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedForm, setSelectedForm] = useState({})
  const [date, setDate] = useState(new Date())

  const { register, handleSubmit, watch } = useForm()

  useEffect(() => {
    dispatch(actions.subFormGet(params.id))
    dispatch(actions.formGet(params?.formId))
    return () => {}
  }, [params])
  // useEffect(() => {
  //   dispatch(actions.formInputAll(form?.name))
  // }, [])

  const handleUpdateValue = async (data) => {
    const confirm = window.confirm('ยืนยันการกรอกข้อมูล')
    if (confirm) {
      const dataSubmit = {
        ...data,
        name: form?.name,
        subForm_name: subForm?.name,
        subForm_id: params?.id,
        date,
        recorder: me?._id,
      }
      // console.log('datasubmit', dataSubmit)
      await dispatch(actions.formInputPost(form?._id, dataSubmit))
      history.goBack()
    }
  }

  const hendleCancle = () => history.goBack()
  //console.log('sub id', subForm?._id)
  if (subForm.isLoading && !subForm.isCompleted) {
    return <SpinnerLoading />
  }
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              แบบฟอร์ม {form?.name}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              {subForm.name}
            </h2>
          </div>
        </div>
      </div>

      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(handleUpdateValue)}>
          <div className="xl:flex lg:flex flex-wrap">
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto h-36"
            >
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                วันที่บันทึก
              </label>
              <div>
                <SingleDatepicker
                  name="date-input"
                  date={date}
                  disabled={
                    form?._id === '6421e4cd502bd44adced1915' ||
                    form?._id === '641a815ae457724bb0b976ba'
                      ? false
                      : true
                  }
                  onDateChange={setDate}
                />
              </div>
            </Box>
            {_.map(subForm?.components, (eachComponent, index) => (
              <div key={index} className="w-full lg:w-1/4">
                <GeneralFormSelector
                  key={index}
                  formData={eachComponent}
                  inUsed
                  register={register}
                  watch={watch}
                />
              </div>
            ))}
          </div>
          <div className="flex gap-2 justify-end">
            <Button
              size="md"
              colorScheme="yellow"
              onClick={() => hendleCancle()}
            >
              {t('utility.cancel')}
            </Button>

            <Button size="md" colorScheme="green" type="submit">
              {t('utility.save')}
            </Button>
          </div>{' '}
        </form>
      </div>
    </div>
  )
}
