import axios from 'axios'
import { PREDICTION_LOG_ALL, PREDICTION_LOG_GET, PREDICTION_LOG_PUT, PREDICTION_LOG_DEL } from '../types'
import moment from 'moment'

export const logPredictionAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + '/logPrediction/').then((res) => {
      console.log('Request Server to Get All Logs')
      dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
    })
  }
}

export const logPredictionOnEachStation = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/station/' + stationId)
      .then((res) => {
        console.log('Request Server to Get All logPrediction')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logOnDaySpecific = (stationId, startDay, finishDay, samplingTime) => {
  return async (dispatch) => {
    let formattedStartDay = moment(startDay).format('YYYY MM DD HH:mm')
    let formatFinishDay = moment(finishDay).format('YYYY MM DD HH:mm')
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          '/logPrediction/specific/' +
          stationId +
          '/' +
          formattedStartDay +
          '/' +
          formatFinishDay +
          '/' + samplingTime
      )
      .then((res) => {
        console.log('Request Server to Get All logPrediction')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logLastestTwenty = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/last20/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Lastest 20 logPrediction')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logByHour = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/last/hour/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Lastest 20 logPrediction')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logByDay = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/last/day/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Day')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logByWeek = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/last/week/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Week')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logByMonth = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/last/month/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Month')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logByYear = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/last/year/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Year')
        dispatch({ type: PREDICTION_LOG_ALL, payload: res.data })
      })
  }
}

export const logGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/logPrediction/' + payload)
      .then((res) => {
        console.log('Request Server to Get an logPrediction')
        if (res.data) {
          dispatch({ type: PREDICTION_LOG_GET, payload: res.data })
        } else {
          dispatch({ type: PREDICTION_LOG_GET, payload: null })
        }
      })
  }
}

export const logPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + '/logPrediction/' + id, payload)
      .then((res) => {
        console.log('Request Server to put an logPrediction')

        dispatch({ type: PREDICTION_LOG_PUT, payload: null })
      })
  }
}

export const logDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + '/logPrediction/' + payload)
      .then((res) => {
        console.log('Request Server to Delete an logPrediction')
        dispatch({ type: PREDICTION_LOG_DEL, payload: null })
      })
  }
}
