import React, { forwardRef } from 'react'
import { Box, Button, Checkbox, Select } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'

import { FormType, InputType } from '../../config/FormType'
import _, { map } from 'lodash'
import MathCalculateFormComponent from './MathCalculateFormComponent'

function GeneralEditFormSelector({
  formData,
  formInput,
  stationData,
  staticValue = 0,
  register,
  inUsed = false,
  watch,
  setValue,
  fetchDataSensor = () => {},
}) {
  console.log('formData in GES', formData)
  console.log('formInput in GES', formInput)

  const me = useSelector((state) => state.me)
  const selectedFormType = formData.formType
  switch (selectedFormType) {
    case FormType.singleInput:
      switch (formData.inputType) {
        case InputType.date:
          return (
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto  h-36"
            >
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                {formData.topic}
                {formData?.required && <span className="text-red-500"> *</span>}
              </label>
              <label className="block uppercase text-gray-500 text-sm  mb-2">
                {formData.description}
              </label>
              <input
                name={formData?.name}
                type="date"
                defaultValue={dayjs(formInput[formData?.name]).format(
                  'D-MM-BBBB'
                )}
                required={inUsed && formData?.required}
                ref={register}
                readOnly={!inUsed}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
              />
            </Box>
          )
        case InputType.time:
          return (
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto h-36"
            >
              <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                {formData.topic}
                {formData?.required && <span className="text-red-500"> *</span>}
              </label>
              <label className="block uppercase text-gray-500 text-sm  mb-2">
                {formData.description}
              </label>
              <input
                name={formData?.name}
                type="time"
                defaultValue={formInput[formData?.name]}
                required={inUsed && formData?.required}
                ref={register}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              />
            </Box>
          )
        case InputType.number:
          return (
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto h-32"
            >
              <div className="flex justify-between ">
                <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                  {formData.topic}
                  {formData?.required && (
                    <span className="text-red-500"> *</span>
                  )}
                </label>
                {formData?.inputSensor?.sensor && (
                  <div>
                    <Button
                      size="small"
                      onClick={() =>
                        fetchDataSensor(
                          formData?.inputSensor?.sensorId,
                          formData?.name
                        )
                      }
                    >
                      ดึงข้อมูล
                    </Button>
                  </div>
                )}
              </div>
              <input
                name={formData?.name}
                type="text"
                required={inUsed && formData?.required}
                defaultValue={formInput[formData?.name]}
                ref={register}
                placeholder={formData.description}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              />
            </Box>
          )
        case InputType.text:
          return (
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto h-32"
            >
              <div className="flex justify-between ">
                <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                  {formData.topic}
                  {formData?.required && (
                    <span className="text-red-500"> *</span>
                  )}
                </label>
                {formData?.inputSensor?.sensor && (
                  <div>
                    <Button
                      size="small"
                      onClick={() =>
                        fetchDataSensor(
                          formData?.inputSensor?.sensorId,
                          formData?.name
                        )
                      }
                    >
                      ดึงข้อมูล
                    </Button>
                  </div>
                )}
              </div>
              <input
                name={formData?.name}
                type="text"
                defaultValue={formInput[formData?.name]}
                required={inUsed && formData?.required}
                ref={register}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder={formData.description}
              />
            </Box>
          )
        case InputType.username:
          return (
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              className=" ml-4 my-2 p-4 w-auto h-32"
            >
              <div className="flex justify-between ">
                <label className="block uppercase text-gray-700 text-md font-bold mb-2">
                  {formData.topic}
                  {formData?.required && (
                    <span className="text-red-500"> *</span>
                  )}
                </label>
                {formData?.inputSensor?.sensor && (
                  <div>
                    <Button
                      size="small"
                      onClick={() =>
                        fetchDataSensor(
                          formData?.inputSensor?.sensorId,
                          formData?.name
                        )
                      }
                    >
                      ดึงข้อมูล
                    </Button>
                  </div>
                )}
              </div>
              <input
                name={formData?.name}
                type="text"
                value={me?.name}
                defaultValue={formInput[formData?.name]}
                required={inUsed && formData?.required}
                ref={register}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder={formData.description}
              />
            </Box>
          )
        default:
          return <div>Singlee</div>
      }
    case FormType.multipleInput:
      return (
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          className=" ml-4 my-2 p-4 w-auto h-36"
        >
          <label className="block uppercase text-gray-700 text-md font-bold mb-2">
            {formData.topic}
          </label>
          <label className="block uppercase text-gray-500 text-sm  mb-2">
            {formData.description}
          </label>
          {map(formData.attribute, (eachAttr) => {
            switch (eachAttr.subInputType) {
              case InputType.date:
                return (
                  <div className="py-2 ">
                    <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                      {eachAttr?.subTopic}
                      {eachAttr?.required && (
                        <span className="text-red-500"> *</span>
                      )}
                    </label>
                    <input
                      name={`${formData?.topic}.${eachAttr.subTopic}`}
                      type="date"
                      defaultValue={
                        formInput[formData?.topic]?.[eachAttr?.subTopic]
                      }
                      required={eachAttr?.required}
                      ref={register}
                      placeholder={eachAttr?.subDesc}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                    />
                  </div>
                )
              case InputType.time:
                return (
                  <div className="py-2">
                    <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                      {eachAttr?.subTopic}{' '}
                      {eachAttr?.required && (
                        <span className="text-red-500"> *</span>
                      )}
                    </label>
                    <input
                      name={`${formData?.name}.${eachAttr.subTopic}`}
                      type="time"
                      defaultValue={
                        formInput[formData?.name]?.[eachAttr?.subTopic]
                      }
                      required={eachAttr?.required}
                      ref={register}
                      placeholder={eachAttr?.subDesc}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-auto ease-linear transition-all duration-150"
                    />
                  </div>
                )
              case InputType.number:
                return (
                  <div className="py-2">
                    <div className="flex justify-between ">
                      <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                        {eachAttr?.subTopic}{' '}
                        {eachAttr?.required && (
                          <span className="text-red-500"> *</span>
                        )}
                      </label>
                      {eachAttr?.inputSensor?.sensor && (
                        <div>
                          <Button
                            size="small"
                            onClick={() =>
                              fetchDataSensor(
                                eachAttr?.inputSensor?.sensorId,
                                `${formData?.topic}.${eachAttr.subTopic}`
                              )
                            }
                          >
                            ดึงข้อมูล
                          </Button>
                        </div>
                      )}
                    </div>
                    <input
                      name={`${formData?.name}.${eachAttr.subTopic}`}
                      type="text"
                      defaultValue={
                        formInput[formData?.name]?.[eachAttr?.subTopic]
                      }
                      required={eachAttr?.required}
                      ref={register}
                      placeholder={eachAttr?.subDesc}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    />
                  </div>
                )
              case InputType.text:
                return (
                  <div className="py-2">
                    <div className="flex justify-between">
                      <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                        {eachAttr?.subTopic}{' '}
                        {eachAttr?.required && (
                          <span className="text-red-500"> *</span>
                        )}
                      </label>
                      {eachAttr?.inputSensor?.sensor && (
                        <div>
                          <Button
                            size="small"
                            onClick={() =>
                              fetchDataSensor(
                                eachAttr?.inputSensor?.sensorId,
                                `${formData?.topic}.${eachAttr.subTopic}`
                              )
                            }
                          >
                            ดึงข้อมูล
                          </Button>
                        </div>
                      )}
                    </div>
                    <input
                      name={`${formData?.name}.${eachAttr.subTopic}`}
                      type="text"
                      defaultValue={
                        formInput[formData?.name]?.[eachAttr?.subTopic]
                      }
                      required={eachAttr?.required}
                      ref={register}
                      placeholder={eachAttr?.subDesc}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    />
                  </div>
                )
              default:
                return <div></div>
            }
          })}
          {/*  */}
        </Box>
      )
    case FormType.null:
      return <h2>NULLLLLLLLLLLL</h2>
    case FormType.checkbox:
      return (
        <h2>
          <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-4 w-auto h-32"
          >
            <label className="block uppercase text-gray-700 text-md font-bold mb-2">
              {formData.topic}
              {formData?.required && <span className="text-red-500"> *</span>}
            </label>
            {_.map(formData?.attribute, (row) => (
              <div className="block uppercase text-gray-700 text-sm font-bold mb-2">
                <Checkbox
                  name={`${formData?.name}.${row.subTopic}`}
                  defaultValue={formInput[formData?.name]?.[row?.subTopic]}
                  defaultChecked={formInput[formData?.name]?.[row?.subTopic]}
                  ref={register}
                >
                  {row?.subTopic}
                </Checkbox>
              </div>
            ))}
          </Box>
        </h2>
      )
    case FormType.selector:
      return (
        <h2>
          <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-4 w-auto h-32"
          >
            <label className="block uppercase text-gray-700 text-md font-bold mb-2">
              {formData.topic}
              {formData?.required && <span className="text-red-500"> *</span>}
            </label>
            <Select
              placeholder={formData.topic}
              defaultValue={formInput[formData?.name]}
              name={formData?.name}
              ref={register}
            >
              {_.map(formData?.attribute, (row) => (
                <option value={row?.subTopic}>{row?.subTopic}</option>
              ))}
            </Select>
          </Box>
        </h2>
      )

    case FormType.calculate:
      return (
        <div>
          <MathCalculateFormComponent
            formData={formData}
            watch={watch}
            register={register}
            setValue={setValue}
          />
        </div>
      )
    default:
      return <h2>Nooooo</h2>
  }
}

export default forwardRef(GeneralEditFormSelector)
