/*eslint-disable*/
import React, { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { firebaseAuth } from 'contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
// import AppLogo from '../../../assets/img/eiotlogo.png'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import Flags from 'country-flag-icons/react/3x2'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp'
import StoreIcon from '@mui/icons-material/Store'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocalMallIcon from '@mui/icons-material/LocalMall'
// import logoImage from '../../../assets/img/logoImage.png'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import WorkIcon from '@mui/icons-material/Work'

import { ThemeProvider } from '@mui/material'
import muiTheme from '../../../muiTheme'

import { Badge } from '@chakra-ui/react'
export default function Sidebar({ isShow = true }) {
  const { t, i18n } = useTranslation()
  const [collapseShow, setCollapseShow] = React.useState('hidden')
  const { handleSignout } = useContext(firebaseAuth)
  const history = useHistory()
  const stations = useSelector((state) => state.station)
  const system = useSelector((state) => state.system)
  const me = useSelector((state) => state.me)
  const [userAllowedStation, setuserAllowedStation] = useState([])

  const [openPo, setOpenPo] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [openHr, setOpenHr] = React.useState(false)
  const [openSubHr, setOpenSubHr] = React.useState(false)
  const [openFi, setOpenFi] = React.useState(false)
  const [openAD, setOpenAD] = React.useState(false)
  const [openWork, setOpenWork] = React.useState(false)

  const onSignOut = async () => {
    await handleSignout()
    history.push('/login')
  }

  useEffect(() => {
    if (me && me?._id) {
      if (_.size(stations?.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = stations.arr
          console.log('Station ', stations)
          console.log('Found Access ALL AREA', allowedStationStack)
          setuserAllowedStation(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations?.arr,
            (station, index) => station._id === me?.duty[index]
          )
          setuserAllowedStation(allowedStation)
        }
      }
    }
    return () => {}
  }, [me, stations])

  const [isDashboard, setIsDashboard] = useState(false)
  const [isStation, setIsStation] = useState({})
  const [isSystem, setIsSystem] = useState(false)
  const [isStationSystem, setIsStationSystem] = useState(false)
  console.log('userAllowedStation : ', userAllowedStation)
  if (isShow === true && system && system.isLoading === true)
    return (
      <ThemeProvider theme={muiTheme}>
        <>
          <nav className="md:left-0 md:block md:fixed md:top-0 bg-white md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-30 py-4 px-6 print-disable">
            <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
              {/* Brand */}
              <Link
                className="md:block hidden text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold px-0"
                to="/"
              >
                {console.log('SYSTEM', system?.logo)}
                <img
                  className="mx-auto"
                  width="100"
                  height="10"
                  src={system?.logo}
                />
              </Link>
              <Link
                className="md:hidden text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold px-0 flex "
                to="/"
              >
                <img className="mx-auto h-10" src={system?.logo} />
                <div className="my-auto pl-4">
                  {system?.name || 'ระบบ E-IoT'}
                </div>
              </Link>
              {/* Toggler */}
              <button
                className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
              >
                <i className="fas fa-bars"></i>
              </button>
              {/* User
              <ul className="md:hidden items-center flex flex-wrap list-none">
                <li className="inline-block relative"></li>
                <li className="inline-block relative"></li>
              </ul> */}

              {/* Collapse */}
              <div
                className={
                  'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
                  collapseShow
                }
              >
                {/* Collapse header */}
                <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
                  <div className="flex flex-wrap">
                    <div className="w-6/12">
                      <Link
                        className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                        to="/"
                      >
                        {system?.name || 'ระบบ E-IoT'}
                      </Link>
                    </div>
                    <div className="w-6/12 flex justify-end">
                      <button
                        type="button"
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        onClick={() => setCollapseShow('hidden')}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>

                {/* Form */}
                {/* <form className="mt-6 mb-4 md:hidden">
                  <div className="mb-3 pt-0">
                    <input
                      type="text"
                      placeholder="Search"
                      className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                    />
                  </div>
                </form> */}

                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                {/* <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline"></h6> */}
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                  }}
                >
                  <Link
                    className={
                      'text-l   block ' +
                      (window.location.href.indexOf('/') !== -1
                        ? 'text-gray-600 hover:text-blue-500'
                        : 'text-blue-600 hover:text-blue-500')
                    }
                    to={'/'}
                  >
                    <ListItemButton>
                      <ListItemIcon
                        className={
                          'fas fa-home text-sm text-gray-600 hover:text-blue-500'
                        }
                      ></ListItemIcon>
                      หน้าแรก
                    </ListItemButton>
                  </Link>

                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/dashboards') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/dashboards'}
                  >
                    <ListItemButton
                      onClick={(e) => setIsDashboard(!isDashboard)}
                    >
                      <ListItemIcon
                        className={
                          'fas fa-chart-line text-2xl' +
                          (window.location.href.indexOf('/dashboards') != -1
                            ? 'text-blue-600 hover:text-blue-500'
                            : 'text-gray-600 hover:text-blue-500')
                        }
                      ></ListItemIcon>
                      แดชบอร์ด
                      {isDashboard ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    {/* render station */}
                    <Collapse in={isDashboard} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {_.size(userAllowedStation) ? (
                          _.map(userAllowedStation, (station, index) => (
                            <Link
                              className={
                                'text-xs   block ' +
                                (window.location.href.indexOf(
                                  '/dashboards/board/' + station._id
                                ) !== -1
                                  ? 'text-blue-500 hover:text-blue-600'
                                  : 'text-gray-800 hover:text-gray-600')
                              }
                              to={'/dashboards/board/' + station._id}
                            >
                              <ListItemButton
                                sx={{ pl: 2 }}
                                onClick={(e) => {
                                  isStation[station?._id] =
                                    !isStation[station?._id]
                                  setIsStation(isStation)
                                }}
                              >
                                <ListItemIcon>
                                  <i
                                    className={
                                      'fas fa-solid fa-bars text-xs text-gray-800 hover:text-blue-600 '
                                    }
                                  ></i>
                                </ListItemIcon>
                                {station.name}
                                {isStation?.[station?._id] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItemButton>

                              {/* render dashboard Graph Diagram */}
                              <Collapse
                                in={isStation?.[station?._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  <Link
                                    className={
                                      'text-xs block ' +
                                      (window.location.href.indexOf(
                                        '/dashboards/favorite/' + station._id
                                      ) !== -1
                                        ? 'text-blue-500 hover:text-blue-600'
                                        : 'text-gray-800 hover:text-gray-600')
                                    }
                                    to={'/dashboards/favorite/' + station._id}
                                  >
                                    <ListItemButton sx={{ pl: 6 }}>
                                      <ListItemIcon>
                                        <i class="fas fa-solid fa-star text-xs text-gray-800 hover:text-blue-600"></i>
                                      </ListItemIcon>
                                      รายการโปรด
                                    </ListItemButton>{' '}
                                  </Link>
                                  <Link
                                    className={
                                      'text-xs block ' +
                                      (window.location.href.indexOf(
                                        '/dashboards/board/' + station._id
                                      ) !== -1
                                        ? 'text-blue-500 hover:text-blue-600'
                                        : 'text-gray-800 hover:text-gray-600')
                                    }
                                    to={'/dashboards/board/' + station._id}
                                  >
                                    <ListItemButton sx={{ pl: 6 }}>
                                      <ListItemIcon>
                                        <i class="fas fa-solid fa-chart-pie text-xs text-gray-800 hover:text-blue-600"></i>
                                      </ListItemIcon>
                                      เกจ
                                    </ListItemButton>{' '}
                                  </Link>
                                  <Link
                                    className={
                                      'text-xs block ' +
                                      (window.location.href.indexOf(
                                        '/dashboards/graph/' + station._id
                                      ) !== -1
                                        ? 'text-blue-500 hover:text-blue-600'
                                        : 'text-gray-800 hover:text-gray-600')
                                    }
                                    to={'/dashboards/graph/' + station._id}
                                  >
                                    <ListItemButton sx={{ pl: 6 }}>
                                      <ListItemIcon>
                                        <i class="fas fa-solid fa-chart-bar text-xs text-gray-800 hover:text-blue-600 "></i>
                                      </ListItemIcon>
                                      กราฟ
                                    </ListItemButton>{' '}
                                  </Link>
                                  <Link
                                    className={
                                      'text-xs block ' +
                                      (window.location.href.indexOf(
                                        '/dashboards/diagram/' + station._id
                                      ) !== -1
                                        ? 'text-blue-500 hover:text-blue-600'
                                        : 'text-gray-800 hover:text-gray-600')
                                    }
                                    to={'/dashboards/diagram/' + station._id}
                                  >
                                    <ListItemButton sx={{ pl: 6 }}>
                                      <ListItemIcon>
                                        <i class="fas fa-solid fa-desktop text-xs text-gray-800 hover:text-blue-600"></i>
                                      </ListItemIcon>
                                      ไดอะแกรม
                                    </ListItemButton>{' '}
                                  </Link>
                                </List>
                              </Collapse>
                            </Link>
                          ))
                        ) : (
                          <></>
                        )}
                      </List>
                    </Collapse>
                  </Link>

                  {/* ควบคุม */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/controls') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/controls'}
                  >
                    {system.enable_controls && (
                      <ListItemButton
                      // onClick={(e) => setIsDashboard(!isDashboard)}
                      >
                        <ListItemIcon
                          className={
                            'fas fa-regular fa-gamepad text-2xl' +
                            (window.location.href.indexOf('/controls') != -1
                              ? 'text-gray-600 hover:text-blue-500'
                              : 'text-blue-500 hover:text-blue-500')
                          }
                        ></ListItemIcon>
                        ควบคุม
                        {/* {isDashboard ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                    )}
                  </Link>

                  {/* ข้อมูลและสถิติ */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/statistics') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/statistics'}
                  >
                    <ListItemButton
                    // onClick={(e) => setIsDashboard(!isDashboard)}
                    >
                      <ListItemIcon
                        className={
                          'fas fa-database text-2xl' +
                          (window.location.href.indexOf('/statistics') != -1
                            ? 'text-gray-600 hover:text-blue-500'
                            : 'text-blue-500 hover:text-blue-500')
                        }
                      ></ListItemIcon>
                      ข้อมูลและสถิติ
                      {/* {isDashboard ? <ExpandLess /> : <ExpandMore />} */}
                    </ListItemButton>
                  </Link>

                  {/* แจ้งเตือน */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/notify') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/notify'}
                  >
                    {system.enable_notify_module && (
                      <ListItemButton
                      // onClick={(e) => setIsDashboard(!isDashboard)}
                      >
                        <ListItemIcon
                          className={
                            'fas fa-bell text-2xl' +
                            (window.location.href.indexOf('/notify') != -1
                              ? 'text-gray-600 hover:text-blue-500'
                              : 'text-blue-500 hover:text-blue-500')
                          }
                        ></ListItemIcon>
                        แจ้งเตือน
                        {/* {isDashboard ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                    )}
                  </Link>

                  {/* บิลค่าไฟ */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf(
                        '/electric-bill-estimator'
                      ) !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/electric-bill-estimator'}
                  >
                    {system.enable_ebe && (
                      <ListItemButton
                      // onClick={(e) => setIsDashboard(!isDashboard)}
                      >
                        <ListItemIcon
                          className={
                            'fas fa-file-invoice text-2xl' +
                            (window.location.href.indexOf(
                              '/electric-bill-estimator'
                            ) != -1
                              ? 'text-gray-600 hover:text-blue-500'
                              : 'text-blue-500 hover:text-blue-500')
                          }
                        ></ListItemIcon>
                        ประมาณการบิลค่าไฟ
                        {/* {isDashboard ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                    )}
                  </Link>

                  {/* สรุปผลการผลิต */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/counting-summary') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/counting-summary'}
                  >
                    {system.enable_counting_summary && (
                      <ListItemButton
                      // onClick={(e) => setIsDashboard(!isDashboard)}
                      >
                        <ListItemIcon
                          className={
                            'fas fa-industry text-2xl' +
                            (window.location.href.indexOf(
                              '/counting-summary'
                            ) != -1
                              ? 'text-gray-600 hover:text-blue-500'
                              : 'text-blue-500 hover:text-blue-500')
                          }
                        ></ListItemIcon>
                        สรุปผลการผลิต
                        {/* {isDashboard ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                    )}
                  </Link>

                  {/* ฟอร์ม */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/humaninput') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/humaninput'}
                  >
                    {system.enable_human_input && (
                      <ListItemButton
                      // onClick={(e) => setIsDashboard(!isDashboard)}
                      >
                        <ListItemIcon
                          className={
                            'fas fa-regular fa-clipboard text-2xl' +
                            (window.location.href.indexOf('/humaninput') != -1
                              ? 'text-gray-600 hover:text-blue-500'
                              : 'text-blue-500 hover:text-blue-500')
                          }
                        ></ListItemIcon>
                        ฟอร์ม
                        {/* {isDashboard ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                    )}
                  </Link>

                  {/* ตั้งค่า */}
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/counting-summary') !== -1
                        ? 'text-blue-600 hover:text-blue-500'
                        : 'text-gray-600 hover:text-blue-500')
                    }
                    to={'/system'}
                  >
                    <ListItemButton onClick={(e) => setIsSystem(!isSystem)}>
                      <ListItemIcon
                        className={
                          'fas fa-cogs text-2xl' +
                          (window.location.href.indexOf('/system') != -1
                            ? 'text-gray-600 hover:text-blue-500'
                            : 'text-blue-500 hover:text-blue-500')
                        }
                      ></ListItemIcon>
                      จัดการระบบ
                      {isSystem ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    <Collapse in={isSystem} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <Link
                          className={
                            'text-xs   block ' +
                            (window.location.href.indexOf('/system/info/') !==
                            -1
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to={'/system/info/'}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <i
                                className={
                                  'fas fa-solid fa-bars text-xs text-right text-gray-800 hover:text-blue-600 '
                                }
                              ></i>
                            </ListItemIcon>
                            จัดการข้อมูลระบบ
                          </ListItemButton>
                        </Link>

                        <Link
                          className={
                            'text-xs   block ' +
                            (window.location.href.indexOf(
                              '/system/connections/'
                            ) !== -1
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to={'/system/connections/'}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <i
                                className={
                                  'fas fa-solid fa-bars text-xs text-gray-800 hover:text-blue-600 '
                                }
                              ></i>
                            </ListItemIcon>
                            จัดการการเชื่อมต่อข้อมูล
                          </ListItemButton>
                        </Link>

                        <Link
                          className={
                            'text-xs   block ' +
                            (window.location.href.indexOf('/system/notify') !==
                            -1
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to={'/system/notify'}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <i
                                className={
                                  'fas fa-solid fa-bars text-xs text-gray-800 hover:text-blue-600 '
                                }
                              ></i>
                            </ListItemIcon>
                            จัดการการแจ้งเตือน
                          </ListItemButton>
                        </Link>

                        <Link
                          className={
                            'text-xs   block ' +
                            (window.location.href.indexOf(
                              '/system/stations'
                            ) !== -1
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to={'/system/stations'}
                        >
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={(e) =>
                              setIsStationSystem(!isStationSystem)
                            }
                          >
                            <ListItemIcon>
                              <i
                                className={
                                  'fas fa-solid fa-bars text-xs text-gray-800 hover:text-blue-600 '
                                }
                              ></i>
                            </ListItemIcon>
                            จัดการสถานี
                          </ListItemButton>
                        </Link>

                        <Link
                          className={
                            'text-xs   block ' +
                            (window.location.href.indexOf(
                              '/system/template'
                            ) !== -1
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to={'/system/template'}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <i
                                className={
                                  'fas fa-solid fa-bars text-xs text-gray-800 hover:text-blue-600 '
                                }
                              ></i>
                            </ListItemIcon>
                            จัดการรูปแบบสถานี
                          </ListItemButton>
                        </Link>

                        <Link
                          className={
                            'text-xs   block ' +
                            (window.location.href.indexOf('/system/users') !==
                            -1
                              ? 'text-blue-500 hover:text-blue-600'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to={'/system/users'}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <i
                                className={
                                  'fas fa-solid fa-bars text-xs text-gray-800 hover:text-blue-600 '
                                }
                              ></i>
                            </ListItemIcon>
                            จัดการผู้ใช้
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                  </Link>
                </List>
                <hr className="my-4 md:min-w-full" />
                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  <li className="items-center">
                    <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block ">
                      <i className="fas fa-user text-gray-500 mr-2 text-sm"></i>{' '}
                      {me?.name}{' '}
                      <Badge colorScheme="gray" className="mb-1">
                        {me?.role}
                      </Badge>
                    </div>
                  </li>
                  <li className="items-center">
                    <div
                      className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                      onClick={() => {
                        onSignOut()
                      }}
                    >
                      <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{' '}
                      Sign Out
                    </div>
                  </li>
                  <li className="items-center">
                    <div className="flex ">
                      <div
                        className="shadow-sm mr-2 cursor-pointer"
                        onClick={() => {
                          i18n.changeLanguage('th')
                        }}
                      >
                        <Flags.TH title="Thai" className="w-5" />
                      </div>
                      <div
                        className="shadow-sm mr-2 cursor-pointer"
                        onClick={() => {
                          i18n.changeLanguage('en')
                        }}
                      >
                        <Flags.GB title="English" className="w-5" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </>
      </ThemeProvider>
    )
  else {
    return <></>
  }
}
