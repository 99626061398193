import React from 'react'
import { Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export default function EstimateBillDetailLists({ history }) {
  const { t } = useTranslation()
  const genEstimateBillLists = () =>
    _.map(history, (_history, index) => (
      <Tr key={_history._id}>
        {/* <Td>{index + 1}</Td> */}
        <Td>
          {' '}
          {dayjs(_history.date).format('D/MM/BBBB')}{' '}
          <span>
            {_history.holiday > 0 ? (
              <span className="text-xs text-green-400">
                {t('dateTime.restDay')}
              </span>
            ) : (
              <span className="text-xs text-red-300">
                {t('dateTime.normalDay')}
              </span>
            )}
          </span>
        </Td>
        <Td>
          {_history.all === 0 ? (
            <div> - </div>
          ) : (
            <>
              {Intl.NumberFormat('en-US').format(
                Number(_history.all).toFixed(2)
              ) + ' '}{' '}
              {t('counter.unit')}
            </>
          )}{' '}
        </Td>
        <Td>
          {' '}
          {_history.onPeak === 0 ? (
            <div> - </div>
          ) : (
            <>
              {Intl.NumberFormat('en-US').format(
                Number(_history.onPeak).toFixed(2)
              ) + ' '}
              {t('counter.unit')}
            </>
          )}{' '}
        </Td>
        <Td>
          {' '}
          {_history.offPeak === 0 ? (
            <div> - </div>
          ) : (
            <>
              {Intl.NumberFormat('en-US').format(
                Number(_history.offPeak).toFixed(2)
              ) + ' '}
              {t('counter.unit')}
            </>
          )}{' '}
        </Td>
        <Td>
          {' '}
          {_history.holiday === 0 ? (
            <div> - </div>
          ) : (
            <>
              {Intl.NumberFormat('en-US').format(
                Number(_history.holiday).toFixed(2)
              ) + ' '}
              {t('counter.unit')}
            </>
          )}{' '}
        </Td>
        <Td>
          {Intl.NumberFormat('en-US').format(
            Number(_history.power).toFixed(2)
          ) + ' '}
          {t('electricBill.baht')}
        </Td>
      </Tr>
    ))
  return (
    <div>
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            {/* <Th>#</Th> */}
            <Th>{t('dateTime.date')}</Th>
            <Th> {t('utility.total')}</Th>
            <Th>{t('electricBill.onPeak')}</Th>
            <Th>{t('electricBill.offPeak')}</Th>
            <Th>{t('electricBill.Holiday')}</Th>
            <Th>{t('electricBill.electricBillOfDay')}</Th>
          </Tr>
        </Thead>
        <Tbody>{genEstimateBillLists()}</Tbody>
      </Table>
    </div>
  )
}
