import axios from "axios";
import { SYSTEM_GET, SYSTEM_POST, SYSTEM_PUT, SYSTEM_DEL } from "../types";

export const systemInfoGet = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/systeminfo/")
      .then((res) => {
        console.log("Request Server to Get System Infomation Record");
        if (res.data) {
          dispatch({ type: SYSTEM_GET, payload: res.data });
        } else {
          dispatch({ type: SYSTEM_GET, payload: null });
        }
      });
  };
};

export const systemInfoPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/systeminfo/", payload)
      .then((res) => {
        console.log("Request Server to Create System Infomation Record");
        dispatch(systemInfoGet());
      });
  };
};

export const systemInfoPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/systeminfo/" + id, payload)
      .then((res) => {
        console.log("Request Server to edit System Information Record");
        dispatch(systemInfoGet());
      });
  };
};
export const systemInfoDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/systeminfo/" + payload)
      .then((res) => {
        console.log("Request Server to Delete System Information Record");
        dispatch({ type: SYSTEM_DEL, payload: null });
      });
  };
};
