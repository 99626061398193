import { storage } from "config/firebase/firebase-client";
export const uploadImageFile = (fileName, file) => {
  return new Promise((resolve, reject) => {
    const uploadTask = storage
      .ref(`${process.env.REACT_APP_NAME}/${fileName}`)
      .putString(file,'data_url');  // data_url

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        // progressVisible(percent);
      },
      function (error) {
        console.log(error);
        reject(error);
      },
      function (complete) {
        console.log("upload file complete!");
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};
