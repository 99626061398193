/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react'
import Gauge from '../common'
import { Button } from '@chakra-ui/react'
import ComponentType from '../../../config/ComponentType'
import _ from 'lodash'
import StarIcon from '@mui/icons-material/Star'

/**
 * @function GeneralGaugeSelector
 * @param {Gauge} gaugeData Gauge Data from station Data
 * @param {Station} stationData Data from station
 * @param {boolean|number|string} staticValue   static data that want to show to gauge it will instead of gauge data in future @default 0
 * @returns Component
 */

export default function GeneralGaugeSelector({
  gaugeData,
  stationData,
  staticValue = 0,
  key,
  handleEditUserFavourite,
  user,
}) {
  // console.log('gaugeData', gaugeData)
  //console.log('stationData', stationData)
  //console.log('key', key)
  const selectedGaugeType = gaugeData.gaugeType
  if (gaugeData.isMultiStateBoolean === true) {
    const multiStateData = gaugeData.onMultiStateBoolean
    const highRef = multiStateData.highStateRef
    const lowRef = multiStateData.lowStateRef
    const isHighValueActiveHigh = multiStateData.highStateActiveHigh
    const isLowValueActiveHigh = multiStateData.lowStateActiveHigh

    const highLevelSensorData = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === highRef
    )
    const lowLevelSensorData = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === lowRef
    )

    switch (selectedGaugeType) {
      case ComponentType.GaugeType.MultiStepBooleanWaterDisplay:
        return (
          <Gauge.MultiStepBooleanWaterDisplay
            highLabel={gaugeData.valueLabel?.high}
            lowLabel={gaugeData.valueLabel?.low}
            swingLabel={gaugeData.valueLabel?.swing}
            lowerSensorData={lowLevelSensorData}
            upperSensorData={highLevelSensorData}
            isLowerActiveHigh={isLowValueActiveHigh}
            isUpperActiveHigh={isHighValueActiveHigh}
          />
        )
      default:
        return <div></div>
    }
  } else {
    const refString = gaugeData.sensorRefString
    const selectedSensorData = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === refString
    )

    const handleFavorite = () => {
      const check = _.find(
        user?.favorite?.gauge,
        (gauges) => gauges === gaugeData?._id
      )
      return (
        <StarIcon
          className={
            check
              ? 'text-yellow-500 hover:text-gray-500'
              : 'text-gray-500 hover:text-yellow-500 '
          }
          onClick={() => handleEditUserFavourite(gaugeData)}
        ></StarIcon>
      )
    }

    const generalProps = {
      graphLabel: gaugeData.gaugeLabel,
      favoriteButton: handleFavorite(),
      selectedSensor: selectedSensorData,
    }

    switch (selectedGaugeType) {
      case ComponentType.GaugeType.BooleanWaterDisplay:
        return (
          <Gauge.BackCompatBooleanWaterDisplay
            isActiveHigh={gaugeData.isActiveHigh}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            sensorData={selectedSensorData}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.BooleanDisplay:
        return (
          <Gauge.BackCompatBooleanDisplay
            sensorData={selectedSensorData}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            highLevelColor={gaugeData.valueColor?.high}
            lowLevelColor={gaugeData.valueColor?.low}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.Meter:
        return (
          <Gauge.BackedMeter
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution?.low}
            sensorData={selectedSensorData}
            unit={gaugeData.valueUnit}
            valueLable={gaugeData.valueNote}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.NumberDisplay:
        return (
          <Gauge.BackCompatNumberDisplay
            sensorData={selectedSensorData}
            unit={gaugeData.valueUnit}
            {...generalProps}
          />
        )

      case ComponentType.GaugeType.StringDisplay:
        return <Gauge.BackCompatStringDisplay sensorData={selectedSensorData} />
      case ComponentType.GaugeType.StaticBoolean:
        return (
          <Gauge.StaticGauge.StaticBoolean
            staticData={staticValue}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            highLevelColor={gaugeData.valueColor?.high}
            lowLevelColor={gaugeData.valueColor?.low}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.StaticNumber:
        return (
          <Gauge.StaticGauge.StaticNumber
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.CountingNumber:
        return (
          <Gauge.StaticGauge.StaticCountingNumber
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.StaticString:
        return <Gauge.StaticGauge.StaticString staticData={staticValue} />
      case ComponentType.GaugeType.StaticMeter:
        return (
          <Gauge.StaticGauge.StaticMeter
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution?.low}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            valueLable={gaugeData.valueNote}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.StaticBooleanWater:
        return (
          <Gauge.StaticGauge.StaticBooleanWater
            isActiveHigh={gaugeData.isActiveHigh}
            labelOnHigh={gaugeData.valueLabel?.high}
            labelOnLow={gaugeData.valueLabel?.low}
            staticData={staticValue}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.StaticMeterClassic:
        return (
          <Gauge.StaticGauge.StaticMeterClassic
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution.low}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            //valueLable={gaugeData.valueNote}
            {...generalProps}
          />
        )
      case ComponentType.GaugeType.StaticMeterColors:
        return (
          <Gauge.StaticGauge.StaticMeterColors
            highResolution={gaugeData.valueResolution?.high}
            lowResolution={gaugeData.valueResolution?.low}
            staticData={staticValue}
            unit={gaugeData.valueUnit}
            valueLable={gaugeData.valueNote}
            {...generalProps}
          />
        )
      default:
        return <div></div>
    }
  }
}
