import React from "react";
import { Center, Container, Spinner } from "@chakra-ui/react";
export default function Spinners() {
  return (
    <div>
      <Center className="m-10">
        <div>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      </Center>
    </div>
  );
}
