/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Code,
  FormControl,
  Input,
  Select,
  Checkbox,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _, { each } from 'lodash'
import { useTranslation } from 'react-i18next'
import 'katex/dist/katex.min.css'
import axios from 'axios'
import Latex from 'react-latex'

import * as actions from '../../redux/actions'
import config from '../../config'

export default function PredictionComponent({
  predictions,
  station,
  placeToEdit = 'station',
}) {
  console.log('Prediction', predictions)
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit, watch } = useForm()
  const dispatch = useDispatch()

  // Merged the prediction Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    if (data.typeSource === 'SENSOR') {
      _.map(station.sensors, (sensor) => {
        if (sensor.refString === data.sensorRef) {
          data.sensorName = sensor.name
        }
      })
      console.log('station.sensors', station.sensors)
    }
    if (data.typeSource === 'VARIABLE') {
      _.map(station.variables, (variable) => {
        if (variable.refString === data.sensorRef) {
          data.variableName = variable.variableName
        }
      })
      console.log('station.variables', station.variables)
    }
    console.log('data', data)

    const selectedPrediction = _.find(
      station.prediction,
      (predictions) => predictions._id === predictions._id
    )
    const selectedPredictionIndex = _.findIndex(
      station.prediction,
      (predictions) => predictions._id === predictions._id
    )
    const mergedpredictionData = _.merge(selectedPrediction, data)
    let editedStation = station
    editedStation.prediction[selectedPredictionIndex] = mergedpredictionData

    // is on Template or on Station FIXME: Magic Code
    if (placeToEdit === 'template') {
      console.log('This is Template not station')
      dispatch(actions.stationTemplatePut(station._id, editedStation))
    } else {
      console.log('This is Station')
      dispatch(actions.stationPut(station._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
    }
    setIsEditorMode(false)
    window.location.reload()
  }

  console.log('sensorName', predictions.sensorName)

  const prepateDeletePrediction = (predictionId) => {
    let confirm = window.confirm('ยืนยันการลบการทำนาย')
    if (confirm) {
      const selectedPredictionIndex = _.findIndex(
        station.prediction,
        (predictions) => predictions._id === predictionId
      )
      let editedStation = station
      if (editedStation.prediction) {
        console.log('Original Equation Stack', editedStation.prediction)
        editedStation.prediction.splice(selectedPredictionIndex, 1)
        console.log('SPLICED')
        console.log('New Equation Stack', editedStation.prediction)
      }

      // is on Template or on Station FIXME: Magic Code
      if (placeToEdit === 'template') {
        console.log('This is Template not station')
        dispatch(actions.stationTemplatePut(station._id, editedStation))
      } else {
        console.log('This is Station')
        dispatch(actions.stationPut(station._id, editedStation)).then(() => {
          dispatch(actions.stationAll())
        })
      }
      setIsEditorMode(false)
    }
  }

  const genSensorName = (source) => {
    if (source === 'SENSOR') {
      return _.map(station.sensors, (sensor, key) => {
        return <option value={sensor.refString}>{sensor.name}</option>
      })
    }
    if (source === 'VARIABLE') {
      return _.map(station.variables, (variable, key) => {
        return <option value={variable.refString}>{variable.name}</option>
      })
    }
  }
  console.log('station.sensors', station.sensors)

  const filterSensor = (dataInput) => {
    const filterS = _.find(
      station.sensors,
      (each) => each?.controlRefString === dataInput
    )
    return filterS
  }

  // const filterVariable = (dataInput) => {
  //   const filterV = _.find(station.variables, (each)=> each?.controlRefString === dataInput )
  // }

  const editorModeComponent = () => (
    <div>
      <FormControl>
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {' '}
          {'เลือกที่มาของข้อมูล'}
        </label>
        <Select
          ref={register}
          size="sm"
          name="typeSource"
          defaultValue={predictions.typeSource}
          placeholder="เลือกที่มาข้อมูล"
        >
          <option value="SENSOR">เซนเซอร์</option>
          <option value="VARIABLE">ตัวแปร</option>
          <option value="HUMANINPUT">การกรอกข้อมูล</option>
        </Select>
      </FormControl>
      <FormControl>
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {' '}
          {'เลือกข้อมูล'}{' '}
        </label>
        <Select
          ref={register}
          size="sm"
          name="sensorRef"
          placeholder="เลือกข้อมูล"
          defaultValue={filterSensor(predictions?.sensorRef)?.controlRefString}
        >
          {genSensorName(predictions.typeSource)}
        </Select>
      </FormControl>
      <FormControl>
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {' '}
          {'เลือกเวลาในการทำนาย'}{' '}
        </label>
        <Select
          ref={register}
          size="sm"
          name="frequency"
          defaultValue={predictions.frequency}
          placeholder="เลือกเวลาในการทำนาย"
        >
          <option value="15min">15 นาทีข้างหน้า</option>
          <option value="1hr">1 ชั่วโมงข้างหน้า</option>
          <option value="12hr">12 ชั่วโมงข้างหน้า</option>
          <option value="1days">1 วันข้างหน้า</option>
        </Select>
      </FormControl>
    </div>
  )

  const displayModeComponent = () => (
    <div className="flex flex-col justify-start">
      <p className="text-sm ">{predictions.sensorName}</p>
      <p className="text-sm"> {predictions.sensorRef}</p>
      <p className="text-sm"> {predictions.frequency}</p>
    </div>
  )

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {predictions.sensorName}{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeletePrediction(predictions._id)}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="flex ">
          <div>
            {isEditorMode === true
              ? editorModeComponent()
              : displayModeComponent()}
          </div>
        </div>
      </form>
    </Box>
  )
}
