import ComponentType from './ComponentType'
import DataType from './DataType'
import MathOperation from './MathOperation'
import UserInfo from './UserInfo'
import UserRole from './UserRoles'
import TypeOfSource from './VariableTypeOfSource'
import TimePeriod from './TimePeriod'
import ImagesType from './ImagesType'
import FormType from './FormType'

export default {
  ComponentType,
  DataType,
  MathOperation,
  UserInfo,
  UserRole,
  TypeOfSource,
  TimePeriod,
  ImagesType,
  FormType,
}
