import React from 'react'
import {
  Radio,
  RadioGroup,
  Stack,
  Button,
  Image,
  Select,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import footer_1 from '../../../../assets/img/ReportPettern/report_footer1.png'
import footer_2 from '../../../../assets/img/ReportPettern/report_footer3.PNG'
import footer_3 from '../../../../assets/img/ReportPettern/report_footer2.png'
import footer_4 from '../../../../assets/img/ReportPettern/report_footer4.png'
import footer_5 from '../../../../assets/img/ReportPettern/report_footer5.png'
import footer_6 from '../../../../assets/img/ReportPettern/report_footer6.PNG'
import footer_7 from '../../../../assets/img/ReportPettern/report_footer7.PNG'
import Header_1 from '../../../../assets/img/ReportPettern/report_header1.png'
import Header_3 from '../../../../assets/img/ReportPettern/report_header1.png'
import Header_2 from '../../../../assets/img/ReportPettern/report_header2.PNG'
import { useState } from 'react'
import _ from 'lodash'
import { FM_BG_005 } from 'components/ReportPDF'

export default function ReportSettingForm({
  station,
  orientations,
  setOrientations,
  footer,
  setFooter,
  header,
  setHeader,
  handleSubmit = () => {},
  fixForm,
  setFixform,
  fixFormName,
  setFixFormName,
}) {
  const { t } = useTranslation()
  const reportList = [
    'FM_BM_001',
    'FM_BM_002',
    'FM_BM_004',
    'FM_PD_001',
    'FM_PD_002',
    'FM_PD_003',
    'FM_PD_004',
    'FM_PD_005',
    'FM_PD_006',
    'FM_PD_007',
    'FM_PD_008',
    'FM_PD_010',
    'FM_BM_003',
    'FM_QC_001',
    'FM_QC_005',
    'FM_QC_007',
    'FM_BG_001',
    'FM_BG_002',
    'FM_BG_003',
    'FM_BG_004',
    'FM_BG_005',
    'FM_BG_006',
    'LabYieldReport',
  ]
  const renderFixForm = () => (
    <div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
          ข้อมูลรูปแบบรายงาน
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <div className="w-full px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รูปแบบรายงาน
                </label>
                <Select
                  placeholder="Select option"
                  onChange={(e) => setFixFormName(e.target.value)}
                >
                  {_.map(reportList, (each, index) => (
                    <option value={each}>{each}</option>
                  ))}
                </Select>
              </div>
              <div className="text-center px-4 gap-0 py-3 ">
                <Button
                  isFullWidth
                  colorScheme="blue"
                  variant="solid"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  {t('utility.save')}{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {' '}
      <div>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      จัดการรูปแบบรายงานในสถานี {station.name}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="lg:px-8 py-4">
                  <RadioGroup onChange={setFixform} value={fixForm}>
                    <Stack direction="row">
                      <Radio value="generate">ฟอร์มอัตโนมัติ</Radio>
                      <Radio value="fix">ฟอร์มสำเร็จ</Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                {fixForm === 'fix' && renderFixForm()}

                {fixForm === 'generate' && (
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      ข้อมูลรูปแบบรายงาน
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              การวางแนวกระดาษ
                            </label>
                            <RadioGroup
                              onChange={setOrientations}
                              value={orientations}
                            >
                              <Stack direction="row">
                                <Radio value="portrait">แนวตั้ง</Radio>
                                <Radio value="landscape">แนวนอน</Radio>
                              </Stack>
                            </RadioGroup>
                          </div>
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              รูปแบบหัวกระดาษ
                            </label>
                            <RadioGroup onChange={setHeader} value={header}>
                              <div className="grid lg:grid-cols-4 grid-cols-2">
                                <Radio value="type_1">แบบที่ 1</Radio>
                                <Radio value="type_2">แบบที่ 2</Radio>
                                <Radio value="type_3">แบบที่ 3</Radio>
                              </div>
                            </RadioGroup>
                          </div>
                          <label
                            className="block uppercase text-gray-700 text-xs ml-4 mb-2"
                            htmlFor="grid-password"
                          >
                            ตัวอย่าง
                          </label>
                          {header === 'type_1' && <Image src={Header_1} />}
                          {header === 'type_2' && <Image src={Header_2} />}
                          {header === 'type_3' && <Image src={Header_3} />}
                          <div className="relative w-full mb-3 pt-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              รูปแบบท้ายกระดาษ
                            </label>
                            <RadioGroup onChange={setFooter} value={footer}>
                              <div className="grid lg:grid-cols-4 grid-cols-2">
                                <Radio value="type_1">แบบที่ 1</Radio>
                                <Radio value="type_2">แบบที่ 2</Radio>
                                <Radio value="type_3">แบบที่ 3</Radio>
                                <Radio value="type_4">แบบที่ 4</Radio>
                                <Radio value="type_5">แบบที่ 5</Radio>
                                <Radio value="type_6">แบบที่ 6</Radio>
                                <Radio value="type_7">แบบที่ 7</Radio>
                              </div>
                            </RadioGroup>
                          </div>
                          <label
                            className="block uppercase text-gray-700 text-xs ml-4 mb-2"
                            htmlFor="grid-password"
                          >
                            ตัวอย่าง
                          </label>
                          {footer === 'type_1' && <Image src={footer_1} />}
                          {footer === 'type_2' && <Image src={footer_2} />}
                          {footer === 'type_3' && <Image src={footer_3} />}
                          {footer === 'type_4' && <Image src={footer_4} />}
                          {footer === 'type_5' && <Image src={footer_5} />}
                          {footer === 'type_6' && <Image src={footer_6} />}
                          {footer === 'type_7' && <Image src={footer_7} />}
                          <image src="../../../../../assets/ReportPettern/report_footer1.png" />

                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                        onClick={() => handleSubmit()}
                      >
                        {t('utility.save')}{' '}
                      </Button>
                    </div>
                    {/* </form> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
