import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions";

// components

import Sidebar from "../components/common/Sidebar/mainSidebar";
import FooterAdmin from "components/common/Footers/FooterAdmin.js";
import SpinnerLoading from "components/Loading/SpinnerLoading";

// views
import Controls from "views/controls/Controls";
import EachStationControl from "views/controls/EachStationControl";

import * as UserRole from "../config/UserRoles";

export default function ControlsLayout() {
  const history = useHistory();
  const authorize_role = [
    UserRole.ROLES.USER,
    UserRole.ROLES.GENERAL,
    UserRole.ROLES.ADMIN,
    UserRole.ROLES.SUPER_USER,
  ]; // Role ที่ได้รับอนุญาต

  const storage_remember = JSON.parse(window.localStorage.eiot_remember); // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("ControlsLayout : ACTIVE");

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.meGet(storage_remember.uid)).then(() => {});
    });

    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      if ((me?.role == authorize_role[2] || me?.role == authorize_role[3])&&system.enable_controls) {
        setIsLoading(true);
        console.log("ได้รับการอนุญาตเข้าถึง");
      } else {
        console.log('ไม่ได้รับอนุญาต')
        alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้')
        history.push('/')
      }
    }
    return () => {};
  }, [me]);

  return isLoading ? (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/controls" exact component={Controls} />
            <Route
              path="/controls/:stationId"
              exact
              component={EachStationControl}
            />
            <Redirect from="/controls" to="/controls" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  );
}
