import pdfMake from 'pdfmake-thaifont-2/build/pdfmake'
import 'pdfmake-thaifont-2/build/vfs_fonts'
import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

pdfMake.fonts = {
  AngsanaNew: {
    normal: 'AngsanaNew.ttf',
    bold: 'AngsanaNew_bold.ttf',
    italics: 'AngsanaNew.ttf',
    bolditalics: 'AngsanaNew.ttf',
  },
  Impac: {
    normal: 'impac.ttf',
    bold: 'impac.ttf',
    italics: 'impac.ttf',
    bolditalics: 'impac.ttf',
  },
  FontAwesome: {
    normal: 'FontAwesome_regular.ttf',
    bold: 'FontAwesome_regular.ttf',
    italics: 'FontAwesome_regular.ttf',
    bolditalics: 'FontAwesome_regular.ttf',
  },
}

const renderMapTime = (data, timeMap) => {
  const content = []

  _.map(timeMap, (time, index) => {
    let indexTime = false
    if (data?.length === 0) {
      return content.push([
        { text: time, style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
        { text: '', style: 'tableContent' },
      ])
    } else {
      //console.log('Time', time, index, data.length)
      _.map(data, (row, indexData) => {
        if (time === row?.TimeStart) {
          indexTime = true
          content.push([
            { text: row?.TimeStart || '', style: 'tableContent' },
            { text: row?.Control1.V || '', style: 'tableContent' },
            { text: row?.Control1.A || '', style: 'tableContent' },
            { text: row?.Control1.KV || '', style: 'tableContent' },
            { text: row?.Control1.mA || '', style: 'tableContent' },
            { text: row?.Control1.SP || '', style: 'tableContent' },
            { text: row?.Control2.V || '', style: 'tableContent' },
            { text: row?.Control2.A || '', style: 'tableContent' },
            { text: row?.Control2.KV || '', style: 'tableContent' },
            { text: row?.Control2.mA || '', style: 'tableContent' },
            { text: row?.Control2.SP || '', style: 'tableContent' },
            { text: row?.Control3.V || '', style: 'tableContent' },
            { text: row?.Control3.A || '', style: 'tableContent' },
            { text: row?.Control3.KV || '', style: 'tableContent' },
            { text: row?.Control3.mA || '', style: 'tableContent' },
            { text: row?.Control3.SP || '', style: 'tableContent' },
            { text: row?.Control4.V || '', style: 'tableContent' },
            { text: row?.Control4.A || '', style: 'tableContent' },
            { text: row?.Control4.KV || '', style: 'tableContent' },
            { text: row?.Control4.mA || '', style: 'tableContent' },
            { text: row?.Control4.SP || '', style: 'tableContent' },
            { text: row?.UserSave || '', style: 'tableContent' },
            { text: row?.comment || '', style: 'tableContent' },
          ])
        } else if (indexTime === false && indexData + 1 === data?.length) {
          content.push([
            { text: time, style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
            { text: '', style: 'tableContent' },
          ])
          return content
        }
      })
    }
  })
  return content
}

const FM_BM_003 = async (form, formInput, subForm, system, date) => {
  const timeMap = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '24:00',
  ]

  const TableBody = renderMapTime(formInput?.arr, timeMap)

  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 120, 20, 10],
    defaultStyle: {
      font: 'AngsanaNew',
      fontSize: '12',
    },

    header(currentPage, pageCount, pageSize) {
      return [
        {
          margin: [20, 30, 20, 10],
          table: {
            widths: ['23%', '54%', '12%', '11%'],
            body: [
              [
                {
                  image: 'logo_imge',
                  rowSpan: 2,
                  fit: [35, 35],
                  alignment: 'center',
                  margin: [0, 2, 0, 0],
                  border: [true, true, true, false],
                },
                {
                  text: `${form?.name || ''} - (FM-BM-003)`,
                  alignment: 'center',
                  bold: true,
                  rowSpan: 3,
                  fontSize: 20,
                  margin: [0, 10],
                },
                {
                  text: `รหัสเอกสาร:  ${form?.docNo || '-'}  `,
                  style: 'header',
                  border: [true, true, true, false],
                  colSpan: 2,
                },
                {},
              ],
              [
                {},
                { text: '', alignment: 'center' },
                {
                  text: `แก้ไขครั้งที่: 0${form?.editVersion || '-'}`,
                  style: 'header',
                  border: [true, true, true, true],
                },
                {
                  text: 'หน้าที่ : ' + currentPage + '/' + pageCount,
                  style: 'header',
                  border: [true, true, true, true],
                },
              ],
              [
                {
                  text: 'THAI TALLOW AND OIL CO.,LTD.',
                  alignment: 'center',
                  bold: true,
                  font: 'Impac',
                  fontSize: 12,
                  border: [true, false, true, true],
                },
                {},
                {
                  text: `วันที่ประกาศใช้ : ${
                    dayjs(form?.enforceDate).format('D MMM YYYY') || '-'
                  }`,
                  style: 'header',
                  border: [true, true, true, true],
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          text: `วันที่ ${dayjs(date).format('D เดือน MMMM พ.ศ. BBBB ')}`,
          alignment: 'right',
          style: 'headerTable',
          fontSize: '14',
          margin: [10, 0, 30, 0],
        },
      ]
    },
    info: {
      title: `${form?.name} ${dayjs(date).format('DD_MM_BBBB')}`,
      subject: '',
    },
    content: [
      {
        margin: [0, 10],
        table: {
          headerRows: 3,
          widths: [
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '4%',
            '8%',
            '8%',
          ],
          body: [
            [
              {
                text: '',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              { text: 'control 1', style: 'headerTable', colSpan: 5 },
              {},
              {},
              {},
              {},
              { text: 'control 2', style: 'headerTable', colSpan: 5 },
              {},
              {},
              {},
              {},
              { text: 'control 3', style: 'headerTable', colSpan: 5 },
              {},
              {},
              {},
              {},
              { text: 'control 4', style: 'headerTable', colSpan: 5 },
              {},
              {},
              {},
              {},
              {
                text: '',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'หมายเหตุ',
                style: 'headerTable',
                margin: [-3, 10, -3, -10],
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: 'Time',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'V',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'A',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'KV',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'mA',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'SP',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'V',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'A',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'KV',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'mA',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'SP',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'V',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'A',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'KV',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'mA',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'SP',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'V',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'A',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'KV',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'mA',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'SP',
                style: 'headerTable',
                border: [true, true, true, false],
              },
              {
                text: 'ผู้บันทึก',
                style: 'headerTable',
                border: [true, false, true, false],
              },
              {
                text: 'การปรับค่า',
                style: 'headerTable',
                margin: [-3, 4, -3, -10],
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '250-380',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '30-54',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '50-110',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '100-150',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '000-020',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '250-380',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '30-54',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '50-110',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '100-150',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '000-020',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '250-380',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '30-54',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '50-110',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '100-150',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '000-020',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '250-380',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '30-54',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '50-110',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '100-150',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '000-020',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'headerTable',
                border: [true, false, true, true],
              },
            ],
            ...TableBody,
          ],
        },
      },
      {
        margin: [20, 30, 20, 10],
        table: {
          widths: ['70%', '30%'],
          body: [
            [
              {
                text: '',
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: 'ผู้ตรวจ',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: '...................................................',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: '(...................................................)',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: 'หัวหน้าแผนก',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],

    images: {
      logo_imge: system?.logo,
    },
    styles: {
      icon: { font: 'FontAwesome' },
      header: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, -3],
      },
      header2: {
        fontSize: 16,
      },
      headerTable: {
        margin: [-3, -2, -3, 0],
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      tableContent: {
        fontSize: 12,
        margin: [0, 0, 0, 0],
        alignment: 'center',
      },
    },
  }
  pdfMake.createPdf(docDefinition).open()
}
export default FM_BM_003
