import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// components
import _ from 'lodash'
import BackButton from 'components/Button/BackButton'
import GeneratEditForm from '../../components/Form/GeneratEditForm'
import Spinners from 'components/Loading/SpinnerLoading'
import { async } from 'pdfmake-thaifont-2/build/pdfmake'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function EditHistoryFrom() {
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const formInput = useSelector((state) => state.formInput)
  const form = useSelector((state) => state.form)
  const subForm = useSelector((state) => state.subForm)
  const station = useSelector((state) => state.station)

  console.log('form', form)

  useEffect(() => {
    dispatch(actions.formInputGet(params.form, params.id))
    dispatch(actions.formGet(params.form))
    dispatch(actions.subFormGet(params.formId))

    return () => {}
  }, [params])

  if (formInput?.isLoading || formInput?.arr) {
    return <Spinners />
  }

  if (formInput?.isLoading && formInput?.isCompleted) {
    return <div>Error</div>
  }

  const handleEdit = async (data) => {
    try {
      await dispatch(actions.formInputPut(params.form, params.id, data))
      await dispatch(actions.formInputAll(params.formId, params.id))

      history.goBack()
    } catch (error) {
      console.log('ERROR')
    }
  }
  if (!formInput?.isLoading && formInput?.isCompleted) {
    return (
      <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                แบบฟอร์มในสถานี {station?.name || ''}
              </h6>

              <h2 className="text-gray-800 text-2xl font-bold font-sans ">
                แก้ไขข้อมูล {formInput?.name || ''}
              </h2>
              <BackButton />
              {formInput?.isCompleted && (
                <GeneratEditForm
                  form={form?.subForm_status ? subForm : form}
                  formInput={formInput}
                  handleEdit={handleEdit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div></div>
}
