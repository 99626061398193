import BackButton from 'components/Button/BackButton'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'
import * as actions from '../../redux/actions'
export default function StationList(params) {
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const station = useSelector((state) => state.station)
  const [allowedDashboard, setAllowedDashboard] = useState()
  useEffect(() => {
    dispatch(actions.stationAll({}))
    return () => {}
  }, [])

  useEffect(() => {
    if (me && me._id && station) {
      if (_.size(station.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = station.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            station.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [station.arr, me])

  console.log('allowedStation', allowedDashboard)

  const renderStation = () => (
    <div className="lg:grid grid-cols-3 gap-1">
      {_.map(allowedDashboard, (row, index) => (
        <div className="my-4  px-4">
          <Link to={`/humaninput/stationlist/${row?._id}`}>
            <div
              className={
                ' rounded text-center  py-8 px-4 lg:h-40  ' +
                (index % 2 == 0
                  ? `shadow  bg-gray-600 transition-transform transform hover:scale-105`
                  : `shadow  bg-gray-500 transition-transform transform hover:scale-105`)
              }
            >
              <i class="fas fa-map-marker-alt text-4xl text-white "></i>
              <p className="text-white mt-4 font-bold text-xl ">
                สถานี {row?.name}
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              เลือกสถานีที่่ต้องการ
            </h6>
            <BackButton />
            {renderStation()}
          </div>
        </div>
      </div>
    </div>
  )
}
