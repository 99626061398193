import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import {
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Switch,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import ImageUpload from '../../../../components/ImageUpload/ImageUpload'
import { uploadImageFile } from '../../../../util/uploadImage'
import { useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import { useTranslation } from 'react-i18next'
import hash from 'object-hash'

export default function EditSystemInfo({ systemInfo }) {
    const { t } = useTranslation()
    const { register, handleSubmit, reset } = useForm()
    const [uploadedLogo, setUploadedLogo] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        return () => {}
    }, [])

    const onSubmit = async (data, e) => {
        console.log(uploadedLogo)
        let confirm = window.confirm('อัพเดทข้อมูล')
        if (confirm) {
            e.preventDefault()
            if (_.size(uploadedLogo)) {
                const filename = hash({ uploadedLogo, date: new Date() })
                uploadImageFile(filename, uploadedLogo[0].data_url).then(
                    async (url) => {
                        console.log('URL : ' + url)

                        data.logo = url
                        e.preventDefault()
                        console.log(data)
                        await dispatch(
                            actions.systemInfoPut(systemInfo._id, data)
                        )
                        alert('บันทึกสำเร็จ')
                        setUploadedLogo([])
                    }
                )
            } else {
                await dispatch(actions.systemInfoPut(systemInfo._id, data))
                alert('บันทึกสำเร็จ')
                setUploadedLogo([])
            }

            // history.goBack();
        }
    }

    if (systemInfo) {
        return (
            <>
                {' '}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap justify-between pb-8">
                        <div className="w-full lg:w-6/12 px-4">
                            <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                                {t('system.systemDataSetting')}
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.systemName')}
                                        </label>
                                        <input
                                            name="name"
                                            type="text"
                                            required
                                            ref={register}
                                            defaultValue={systemInfo.name}
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder={t('system.systemName')}
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.systemDesc')}
                                        </label>
                                        <textarea
                                            name="description"
                                            type="text"
                                            ref={register}
                                            defaultValue={
                                                systemInfo.description
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder={t('system.systemDes')}
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.companyInfo')}
                                        </label>

                                        <input
                                            name="systemOwner"
                                            type="text"
                                            ref={register}
                                            defaultValue={
                                                systemInfo.systemOwner
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder="My Company Co.,ltd."
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.companySite')}
                                        </label>

                                        <input
                                            name="systemOwnerURL"
                                            type="text"
                                            ref={register}
                                            defaultValue={
                                                systemInfo.systemOwnerURL
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder="https://www.psu.ac.th"
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.sponsorInfo')}
                                        </label>

                                        <input
                                            name="systemSponsor"
                                            type="text"
                                            ref={register}
                                            defaultValue={
                                                systemInfo.systemSponsor
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder="PSU Science Park"
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.sponsorSite')}
                                        </label>

                                        <input
                                            name="systemSponsorURL"
                                            type="text"
                                            ref={register}
                                            defaultValue={
                                                systemInfo.systemSponsorURL
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder="https://psusp.or.th/"
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('system.systemLogo')}
                                        </label>
                                        <ImageUpload
                                            images={uploadedLogo}
                                            setImages={setUploadedLogo}
                                            preview_size="250"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-6/12 px-4">
                            <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                                {t('system.displayedModule')}
                            </h6>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.monitoringModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_dashboard"
                                        ref={register}
                                        defaultChecked={
                                            systemInfo.enable_dashboard
                                        }
                                    />
                                </FormControl>
                            </div>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.dataAndStatisticModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_statistics"
                                        ref={register}
                                        defaultChecked={
                                            systemInfo.enable_statistics
                                        }
                                    />
                                </FormControl>
                            </div>
                            
                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.controlModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_controls"
                                        ref={register}
                                        defaultChecked={
                                            systemInfo.enable_controls
                                        }
                                    />
                                </FormControl>
                            </div>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.electricBillModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_ebe"
                                        ref={register}
                                        defaultChecked={systemInfo.enable_ebe}
                                    />
                                </FormControl>
                            </div>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.notifyModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_notify_module"
                                        ref={register}
                                        defaultChecked={
                                            systemInfo.enable_notify_module
                                        }
                                    />
                                </FormControl>
                            </div>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.countingModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_counting_summary"
                                        ref={register}
                                        defaultChecked={
                                            systemInfo.enable_counting_summary
                                        }
                                    />
                                </FormControl>
                            </div>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            โมดูลการกรอกข้อมูล
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_human_input"
                                        ref={register}
                                        defaultChecked={
                                            systemInfo.enable_human_input
                                        }
                                    />
                                </FormControl>
                            </div>

                            <div className="w-full px-4 pb-3">
                                <FormControl display="flex">
                                    <FormLabel>
                                        <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            {t('module.systemModule')}
                                        </div>
                                    </FormLabel>
                                    <Switch
                                        size="lg"
                                        name="enable_system"
                                        ref={register}
                                        isDisabled={true}
                                        defaultChecked={
                                            systemInfo.enable_system
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>{' '}
                    <div className="text-center px-4 gap-0 py-3 ">
                        <Button
                            isFullWidth
                            colorScheme="blue"
                            variant="solid"
                            type="submit"
                        >
                            {t('utility.save')}{' '}
                        </Button>
                    </div>
                </form>{' '}
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
